<template>
  <div>
    <a-card class="card-ex" :bordered="false" :body-style="{ padding: 0 }" :style="{ height: height + 'px' }">
      <div class="title"><span class="title-line"></span>{{ title }}</div>
      <div class="content">
        <div class="empty" :style="{ height: height - 40 + 'px' }" v-if="isEmpty">
          <a-empty :image="simpleImage" />
        </div>
        <slot />
      </div>
    </a-card>
  </div>
</template>
<script>
import { Empty } from 'ant-design-vue'
export default {
  components: {
    AEmpty: Empty
  },
  props: {
    title: {
      type: String,
      default: () => {}
    },
    isEmpty: {
      type: Boolean,
      default: false
    },
    height: {
      type: Number,
      default: 360
    }
  },
  beforeCreate() {
    this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE
  }
}
</script>
<style lang="less" scoped>
.card-ex {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  border-radius: 16px;
  .title {
    line-height: 70px;
    padding-left: 10px;
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 600;
    font-style: 'normal';
    font-family: 'sans-serif';
    .title-line {
      &:after {
        content: '';
        margin-top: -3px;
        padding-left: 10px;
        border-left: 4px solid #06aa9c;
        height: 23px;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
  .content {
    flex: 1;
    .empty {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      margin-bottom: 0;
      height: 100%;
    }
  }
}
</style>
<style lang="less">
.card-ex {
  .ant-card-body {
    height: 100%;
    display: flex;
    flex-direction: column;
    .content {
      flex: 1;
    }
  }
}
</style>
