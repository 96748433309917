var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-card",
        {
          staticClass: "card-ex",
          style: { height: _vm.height + "px" },
          attrs: { bordered: false, "body-style": { padding: 0 } },
        },
        [
          _c("div", { staticClass: "title" }, [
            _c("span", { staticClass: "title-line" }),
            _vm._v(_vm._s(_vm.title)),
          ]),
          _c(
            "div",
            { staticClass: "content" },
            [
              _vm.isEmpty
                ? _c(
                    "div",
                    {
                      staticClass: "empty",
                      style: { height: _vm.height - 40 + "px" },
                    },
                    [_c("a-empty", { attrs: { image: _vm.simpleImage } })],
                    1
                  )
                : _vm._e(),
              _vm._t("default"),
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }